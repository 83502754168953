@import 'flex';

.slick-carousel-landing {
	padding-top: 85px;
	@media screen and (min-width: 992px) {
		padding-top: 120px;
	}
	.hero.homepage-slides {
		height: 0;
		background-repeat: no-repeat;
		padding: 0;
		padding-bottom: 400px;
		overflow: hidden;
		position: relative;
		@media screen and (min-width: 768px) {
			padding-bottom: 600px / 1700px * 100%;
		}
		> div {
			position: absolute;
			top: 0;
			bottom: 50px;
			left: 0;
			right: 0;
			width: 100%;
			@include flexbox;
			@include justify-content(center);
			@include align-items(center);
			h1 {
				margin: 0;
				font-size: 38px;
				text-shadow: 2px 2px 2px black;
				@media screen and (min-width: 768px) {
					font-size: 50px;
				}
				@media screen and (min-width: 992px) {
					font-size: 60px;
				}
				@media screen and (min-width: 1240px) {
					font-size: 80px;
				}
			}
		}
	}
	.slick-dots {
		bottom: 15px;
	}
}

nav.navbar {
	.navbar-brand {
		@media screen and (min-width: 992px) {
			width: 165px;
		}
	}
}

// About page
.makers-tiles {
	.slide-caption {
		h3 {
			height: 40px !important;
		}
	}
}